<template>
    <div>
        <!--pages/user/message/message.wxml-->
        <!-- 通知消息 -->
        <div class="message-wrap grey-bg">
          <div class="message rich-text text-justify-t" v-html="message">

          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "message",
  data(){
    return{
      show: false,
      message: ''
    }
  },
  created() {
    if(this.$route.params.id){
      //  获取详情
      this.getShow()
    }else {
     this.$router.replace({path: '/'})
    }
  },
  methods: {
  //  获取详情
    getShow(){
      let url = this.$api.Notices + '/' + this.$route.params.id;
      this.$http.get(url).then( res => {
        if(res.data.success){
          this.message = res.data.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/message/message.scss";

</style>